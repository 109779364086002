import React, { Component } from 'react';
import ContentWrapper from '../../template/ContentWrapper';
import VoiceServices from '../../../services/CodegirlsVoiceServices';
import DropdownServices from '../../../services/DropdownServices';
import Formgroup from '../../Basecompoents/Formgroup';
import Input from '../../Basecompoents/Input';
import Label from '../../Basecompoents/Label';
import { Link } from 'react-router-dom';
import { config } from '../../../config';
import { AccessDeniedUrl } from '../../../hooks';
import TextEditor from '../../Basecompoents/TextEditor';
import Select from '../../Basecompoents/Select';
import Axios from 'axios';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Voice_title: "",
            Voice_short_content : "",
            Voice_content: "abc",
            Voice_slug: "",
            Voice_Image: "",
            Voice_job_title: "",
            Voice_girl_skill: "",
            // Voice_is_featured: false,
            oldVoice_Image: "",
            oldVoice_Featured: "",
            Voice_location : "",
            // Voice_girl_expertise:"",
            Voice_before_codegirls_description:"",
            Voice_Featured:"",
            option: 'add',
            // categories: [],
            // authors: [],
            // id: '',
            errors: "",
            success: "",
            title: "Add",
        };

    }
    eventHandler = (content, editor) => {
        this.setState({
            Voice_content: content,
        });
    }
    cgeventHandler = (content, editor) => {
        this.setState({
            Voice_before_codegirls_description: content,
        });
    }
    expertiseeventHandler = (content, editor) => {
        this.setState({
            Voice_girl_expertise: content,
        });
    }
    onChangeEvent = (event) => {
        const { name, files } = event.target;
    
        if (name === "Voice_Image" || name === "Voice_Featured") {
            if (files.length > 0) {
                const file = files[0];
                const ext = file.name.split('.').pop().toLowerCase();
                const validExtensions = ['jpg', 'jpeg', 'png'];
    
                if (file.size <= 10485760) {
                    if (validExtensions.includes(ext)) {
                        this.setState({ [name]: file });
    
                        if (name === "Voice_Image") {
                            document.querySelector(".label-Voice-Image").textContent = file.name;
                        } else if (name === "Voice_Featured") {
                            document.querySelector(".label-Voice-Featured").textContent = file.name;
                        }
    
                        this.setState({ errors: { ...this.state.errors, [name]: null } });
                    } else {
                        this.setState({ errors: { ...this.state.errors, [name]: "File extension is not valid." } });
                    }
                } else {
                    this.setState({ errors: { ...this.state.errors, [name]: "File size is too big." } });
                }
            }
        } else if (name === "Voice_is_featured") {
            this.setState({ [name]: event.target.checked ? event.target.value : '' });
        } else {
            this.setState({ [name]: event.target.value });
        }
    };
    
 
    
    async componentDidMount() {
        console.log("DATA IS HERE", this.props)
        document.title = `${document.title} | ${this.state.title}`;
        //  document.title = `abcd`;
        const user = JSON.parse(localStorage.getItem('user'));
        const DropdownService = new DropdownServices();
        // const categories = await DropdownService.getCategoryDropdown(user.token);
        // const authors = await DropdownService.getAuthorDropdown(user.token);
        // if (categories.length > 0) {
        //     this.setState({ categories, authors })
        // }
        const pathname = this.props.location.pathname.split('/')
        if (pathname.indexOf('edit') > -1) {
            const id = pathname.pop();
            const option = 'edit';
            try {

                const VoiceService = new VoiceServices();
                const records = await VoiceService.getDetailVoice(user.token, id);
                console.log("Records is hereeee", records)
                if (records.hasOwnProperty("status") && (records.status === "TokenExpired" || records.status === "unauthorized")) {
                    throw records
                }
                if (records.hasOwnProperty("status") && records.status === "success") {
                    const data = records.data;
                    data['oldVoice_Image'] = data.Voice_Image;
                    data['oldVoice_Featured'] = data.Voice_Featured;
                    data.Voice_Image = "";
                    data.Voice_Featured = "";
                    this.setState({ ...data, id, option, title: 'Edit' });
                }
                
            }
            catch (error) {
                await AccessDeniedUrl(error.status, this.props);
            }
        }
    }
    onSubmithandler = async (event) => {
        const VoiceService = new VoiceServices();
        event.preventDefault();
        try {
            console.log("try" , this.state);
            document.title = `${document.title} | ${this.state.title}`;
            const user = JSON.parse(localStorage.getItem('user'));
            const response = await VoiceService.addVoice(this.state, user.token);
            if (response.hasOwnProperty("status") && (response.status === "TokenExpired" || response.status === "unauthorized")) {
                throw response
            }
            this.setState({
                Voice_content: "",
                Voice_title: "",
                Voice_slug: "",
                Voice_job_title: "",
                Voice_location : "",
                // Voice_girl_expertise:"",
                Voice_before_codegirls_description:"",
                Voice_short_content:"",
                errors: "",
                success: response.message,
            });
            if (response.hasOwnProperty("status")) {
                localStorage.setItem("recordsDetails", JSON.stringify(response.status))
                this.props.history.push("/manage-voice-of-codegirls/view")

            }
        } catch (errors) {
            console.log("catch error", errors);

            if (errors.status === "TokenExpired") {
                this.props.tokenRemove();
            } else if (errors.status === "unauthorized") {
                this.props.history.push("/401")
            } else {
                this.setState({ errors });
            }
        }
    };
    render() {

        return (
            <ContentWrapper
                {...this.props}
                pageHeading={`${this.state.title} Voice`} >
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <form onSubmit={this.onSubmithandler}>
                                    <Formgroup>
                                        <Label text="Girl Name: *" />
                                        <Input value={this.state.Voice_title} onChange={this.onChangeEvent} name="Voice_title" className="form-control" type="text" />
                                        {this.state.errors.Voice_title && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_title}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Job Title: *" />
                                        <Input value={this.state.Voice_job_title} onChange={this.onChangeEvent} name="Voice_job_title" className="form-control" type="text" />
                                        {this.state.errors.Voice_job_title && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_job_title}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Girl Location: *" />
                                        <Input value={this.state.Voice_location} onChange={this.onChangeEvent} name="Voice_location" className="form-control" type="text" />
                                        {this.state.errors.Voice_location && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_location}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Girl Skills: *" />
                                        <Input value={this.state.Voice_girl_skill} onChange={this.onChangeEvent} name="Voice_girl_skill" className="form-control" type="text" placeholder="Enter like this HTML,CSS,JS" />
                                        {this.state.errors.Voice_girl_skill && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_girl_skill}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Slug: *" />
                                        <Input value={this.state.Voice_slug} onChange={this.onChangeEvent} name="Voice_slug" className="form-control" type="text" />
                                        {this.state.errors.Voice_slug && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_slug}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Short Description: *" />
                                        <textarea
                                            className="form-control"
                                            onChange={this.onChangeEvent}
                                            value={this.state.Voice_short_content}
                                            name="Voice_short_content"
                                            rows="4"
                                        ></textarea>
                                        {this.state.errors.Voice_short_content && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_short_content}
                                            </small>
                                        )}
                                    </Formgroup>
                                    <Formgroup>
                                        <Label text="Before Codegirls: *" />
                                        <TextEditor onChange={this.cgeventHandler} option={this.state.option} value={this.state.Voice_before_codegirls_description} />
                                        {this.state.errors.Voice_before_codegirls_description && (
                                            <small className="text-danger">
                                                {this.state.errors.Voice_before_codegirls_description}
                                            </small>
                                        )}
                                    </Formgroup>
                                  
                                  <Formgroup>
                                    <Label text="Image: * " for="InputFile" />
                                    <small className="text-danger"> Max file size : 10MB | allowed file type : jpg,jpeg,png</small>
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <Input className="custom-file-input" onChange={this.onChangeEvent} accept="image/jpeg,image/png" name="Voice_Image" type="file" />
                                            <Label text="Image" for="InputFile" className="custom-file-label label-Voice-Image" />
                                        </div>
                                    </div>
                                    {this.state.errors.Voice_Image && (
                                        <small className="text-danger">
                                            {this.state.errors.Voice_Image}
                                        </small>
                                    )}
                                    {this.state.option === "edit" ? (
                                        <Link to={`${config.UploadDir}${this.state.oldVoice_Image}`} target="_blank">{`${config.UploadDir}${this.state.oldVoice_Image}`}</Link>
                                    ) : ''}
                                </Formgroup>

                                <Formgroup>
                                    <Label text="Featured Image: * " for="InputFile" />
                                    <small className="text-danger"> Max file size : 10MB | allowed file type : jpg,jpeg,png</small>
                                    <div className="input-group">
                                        <div className="custom-file">
                                            <Input className="custom-file-input" onChange={this.onChangeEvent} accept="image/jpeg,image/png" name="Voice_Featured" type="file" />
                                            <Label text="Image" for="InputFile" className="custom-file-label label-Voice-Featured" />
                                        </div>
                                    </div>
                                    {this.state.errors.Voice_Featured && (
                                        <small className="text-danger">
                                            {this.state.errors.Voice_Featured}
                                        </small>
                                    )}
                                    {this.state.option === "edit" ? (
                                        <Link to={`${config.UploadDir}${this.state.oldVoice_Featured}`} target="_blank">{`${config.UploadDir}${this.state.oldVoice_Featured}`}</Link>
                                    ) : ''}
                                </Formgroup>

                                   
                                    <Formgroup>
                                        <Input className="btn btn-success float-right" type="submit" value="Save" />
                                        <Link to="/manage-Voice/view" className="cancel btn btn-danger float-right  mr-2">Cancel</Link>
                                        {this.state.success && (
                                            <small className="success-text">{this.state.success}</small>
                                        )}
                                    </Formgroup>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default Form;